<style scoped>
@media (min-width: 400px) and (max-width: 991.98px) {
  .aside .aside-primary {
    width: 200px !important;
  }
}
</style>

<template>
  <!-- begin:: Aside -->
  <div class="aside aside-left d-flex aside-fixed" id="kt_aside" ref="kt_aside">
    <!--begin::Primary-->
    <div
      class="
        aside-primary
        d-flex
        flex-column
        align-items-center
        flex-row-auto
        w-200
      "
    >
      <!--begin::Brand-->
      <KTBrand></KTBrand>
      <!--end::Brand-->
      <!--begin::Nav Wrapper-->
      <div
        class="
          aside-nav
          d-flex
          flex-column
          align-items-center
          flex-column-fluid
          py-5
          scroll scroll-pull
          ps
        "
        style="height: 528px; overflow: hidden"
      >
        <!--begin::Nav-->
        <ul class="nav flex-column" role="tablist" ref="navigation-tab">
          <!--begin::Item-->

          <li
            v-for="(item, index) in menu.filter((data) => data.show != false)"
            :key="item.to"
            class="nav-item mb-3"
            data-placement="right"
            data-container="body"
            data-boundary="window"
          >
            <router-link
              :to="item.to"
              class="nav-link btn btn-clean btn-lg"
              data-toggle="tab"
              @click.native="setActiveTab"
              :data-tab="index"
            >
              <span class="svg-icon svg-icon-xl">
                <!--begin::Svg Icon-->
                <inline-svg :src="item.icon" />
                <!--end::Svg Icon-->
              </span>
              {{ item.name }}
            </router-link>

            <div
              class="separator separator-solid mt-2"
              v-if="item.separator"
            ></div>
          </li>
        </ul>
        <!--end::Nav-->
      </div>
      <div
        class="
          aside-footer
          d-flex
          flex-column
          align-items-center
          flex-column-auto
          py-4 py-lg-10
        "
      >
        <!--begin::Aside Toggle-->
        <!--        <span-->
        <!--          class="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"-->
        <!--          id="kt_aside_toggle"-->
        <!--          ref="kt_aside_toggle"-->
        <!--          data-placement="right"-->
        <!--          data-container="body"-->
        <!--          data-boundary="window"-->
        <!--          v-b-tooltip.hover.right="'Toggle Aside'"-->
        <!--        >-->
        <!--          <i class="ki ki-bold-arrow-back icon-sm"></i>-->
        <!--        </span>-->
        <!--end::Aside Toggle-->
        <!--begin::Quick Actions-->
        <!--        <KTQuickActions></KTQuickActions>-->
        <!--end::Quick Actions-->
        <!--begin::Quick Panel-->
        <!--        <KTQuickPanel></KTQuickPanel>-->
        <!--end::Quick Panel-->
        <!--begin::User-->
        <KTQuickUser></KTQuickUser>
        <!--end::User-->
      </div>
      <!--end::Footer-->
    </div>
    <!--end::Primary-->
    <!--begin::Secondary-->
    <!-- <div class="aside-secondary d-flex flex-row-fluid">
      <div
        class="aside-workspace scroll scroll-push my-2 ps"
        style="height: 824px; overflow: hidden"
      >
        <div v-if="currentAttempt.soal" class="p-4 mx-auto"> 
          <h3 class="p-2 p-lg-3 my-1 my-lg-3">Navigasi Soal</h3>
          <div class="px-2" style="margin-bottom: 25px">
            <div class="d-flex flex-column w-100 mb-4">
              <div
                class="d-flex align-items-center justify-content-between mb-2"
              >
                <span class="text-muted font-size-sm font-weight-bold">
                  Progress
                </span>
                <span class="text-muted mr-2 font-size-sm font-weight-bold">
                  {{
                    (
                      ((currentAttempt.soal.filter(
                        (soal) =>
                          soal.jawabans.filter((item) => item.selected).length >
                          0
                      ).length +
                        currentAttempt.soal.filter((soal) => soal.jawaban)
                          .length) /
                        currentAttempt.soal.length) *
                      100
                    ).toFixed(0)
                  }}%
                </span>
              </div>
              <div class="progress progress-xs w-100">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  :style="{
                    width:
                      ((currentAttempt.soal.filter(
                        (soal) =>
                          soal.jawabans.filter((item) => item.selected).length >
                          0
                      ).length +
                        currentAttempt.soal.filter((soal) => soal.jawaban)
                          .length) /
                        currentAttempt.soal.length) *
                        100 +
                      '%',
                  }"
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div class="row m-0 mb-2">
              <div class="col py-6 border rounded mr-1">
                <div class="font-size-sm text-muted font-weight-bold">
                  Belum Terjawab
                </div>
                <div
                  class="
                    font-size-h6 font-weight-bolder
                    d-flex
                    align-items-center
                  "
                >
                  <div class="symbol symbol-20 mr-2">
                    <span class="symbol-label bg-secondary"> </span>
                  </div>
                  {{
                    currentAttempt.soal.filter(
                      (soal) =>
                        soal.tipe != "essay" &&
                        soal.jawabans.filter((item) => item.selected).length ==
                          0
                    ).length +
                    currentAttempt.soal.filter(
                      (soal) => soal.tipe == "essay" && !soal.jawaban
                    ).length
                  }}
                </div>
              </div>
              <div class="col py-6 border rounded ml-1">
                <div class="font-size-sm text-muted font-weight-bold">
                  Terjawab
                </div>
                <div
                  class="
                    font-size-h6 font-weight-bolder
                    d-flex
                    align-items-center
                  "
                >
                  <div class="symbol symbol-20 mr-2">
                    <span class="symbol-label bg-primary"> </span>
                  </div>
                  {{
                    currentAttempt.soal.filter(
                      (soal) =>
                        soal.jawabans.filter((item) => item.selected).length > 0
                    ).length +
                    currentAttempt.soal.filter((soal) => soal.jawaban).length
                  }}
                </div>
              </div>
            </div>
            <div class="row m-0">
              <div class="col py-6 border rounded mr-1">
                <div class="font-size-sm text-muted font-weight-bold">
                  Ditandai
                </div>
                <div
                  class="
                    font-size-h6 font-weight-bolder
                    d-flex
                    align-items-center
                  "
                >
                  <div class="symbol symbol-20 mr-2">
                    <span class="symbol-label bg-info"> </span>
                  </div>
                  {{
                    currentAttempt.soal.filter((soal) => soal.flagged).length
                  }}
                </div>
              </div>
              <div class="col py-6 ml-1"></div>
            </div>
          </div>
          <ul
            style="
              list-style: none;
              columns: 5;
              -webkit-columns: 5;
              -moz-columns: 5;
            "
            class="px-2 mt-4"
          >
            <li v-for="(soal, i) in currentAttempt.soal" :key="soal.id_soal">
              <span
                @click="scrollToSoal(i)"
                :class="`btn btn-icon btn-sm symbol ${
                  soal.jawabans.filter((item) => item.selected).length +
                    (soal.jawaban ? 1 : 0) >
                  0
                    ? soal.flagged
                      ? 'btn-light-info active'
                      : 'btn-light-primary' + ' active'
                    : soal.flagged
                    ? 'btn-light-info active'
                    : 'btn-secondary'
                }`"
              >
                <span
                  :class="`svg-icon svg-icon-md ${
                    soal.flagged ? 'svg-icon-light' : ''
                  }`"
                >
                  {{ i + 1 }}
                </span>
              </span>
            </li>
          </ul>
        </div> 
      </div>
    </div> -->
  </div>
  <!-- end:: Aside -->
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { mapGetters } from "vuex";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
import KTLayoutAsideToggle from "@/assets/js/layout/base/aside-toggle.js";
import KTBrand from "@/view/layout/brand/Brand.vue";
import KTMenu from "@/view/layout/aside/Menu.vue";
// import KTQuickActions from "@/view/layout/extras/offcanvas/QuickActions.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
// import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import { getMenu } from "@/core/menu.js";

export default {
  name: "KTAside",
  data() {
    return {
      insideTm: 0,
      outsideTm: 0,
      tabIndex: 0,
    };
  },
  components: {
    KTBrand,
    // KTMenu,
    // KTQuickActions,
    KTQuickUser,
    // KTQuickPanel
  },
  destroyed() {
    localStorage.removeItem("navigationTab");
  },
  mounted() {
    this.setActivePreviousTab();
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);

      // Init Aside Menu
      KTLayoutAsideMenu.init(this.$refs["kt_aside_menu"]);

      // Init Aside Toggle
      KTLayoutAsideToggle.init(this.$refs["kt_aside_toggle"]);
    });
  },
  methods: {
    getMenu,
    scrollToSoal(i) {
      this.$root.$emit("scrollToSoal", i);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("navigationTab")) || 0;
      const links = this.$refs["navigation-tab"].querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      this.$refs["navigation-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");

      if (this.isMobile()) {
        this.$refs["kt_aside"].classList.remove("aside-on");
        document.querySelector(".aside-overlay").remove();
        document
          .querySelector(".mobile-toggle-active")
          .classList.remove(".mobile-toggle-active");
      }

      // keep active tab
      localStorage.setItem("navigationTab", this.tabIndex);
    },
  },
  computed: {
    ...mapGetters([
      "layoutConfig",
      "getClasses",
      "currentAttempt",
      "currentUser",
      "authUser",
      "currentClassSidebar",
    ]),

    /**
     * Get extra classes for menu based on the options
     */
    asideMenuClass() {
      const classes = this.getClasses("aside_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },
    isSiswa() {
      return true;
    },
    menu() {
      return getMenu(this.$store.getters.currentUser.role);
    },
    // roleMenus() {
    //   return Menus.getMenu(currentUser.role);
    // }
  },
};
</script>
